import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  Space,
  Tabs,
  Tag,
  Typography,
  Divider,
} from "antd";

import React, { useState } from "react";
import { AlignLeftOutlined, ReloadOutlined } from "@ant-design/icons";
import { BorderedContent } from "../Common/Components/BorderedContent";
import { getData } from "./ciscoCalls.tsx/get";
import TabPage from "../Common/Components/TabPage";

// import { getData } from "./fortinetCalls.tsx/get";
// import TabPage from "./TabPage";
// import { BorderedContent } from "../Common/Components/BorderedContent";
// import ExportToExcel from "../Common/Components/Exports/ExportToExcel";

const { Text } = Typography;

export type ciscoAccountType = {
  username: string;
  password: string;
};

const CiscoConnector = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(false);
  const [ciscoAccount, setCiscoAccount] = useState<
    ciscoAccountType | undefined
  >(undefined);

  const reset = () => {
    setData([]);
    setCiscoAccount(undefined);
  };

  const [form] = Form.useForm<{ ciscoUser: string; ciscoPass: string }>();
  const ciscoUserInput = Form.useWatch("ciscoUser", form);
  const ciscoPassInput = Form.useWatch("ciscoPass", form);

  const getDevices = async (ciscoUser: string, ciscoPass: string) => {
    setLoading(true);
    setCiscoAccount({
      username: ciscoUser,
      password: ciscoPass,
    });
    // // Call the API with the API key
    const response = await getData("get_ui", {
      user: ciscoUser,
      password: ciscoPass,
    });
    response.data && setData(response.data);
    response.error && setError(response.error);
    setLoading(false);
  };

  const onFinish = async (values: any) => {
    console.log("Cisco User:", values.ciscoUser);
    getDevices(values.ciscoUser, values.ciscoPass);
  };

  const payloads = {};
  data.forEach(({ name, data }) => {
    payloads[name] = data;
  });
  console.log(payloads);

  const operations = (
    <>
      {/* <ExportToExcel payloads={payloads} /> */}
      <Divider type="vertical" />
      <Button
        size="small"
        icon={<ReloadOutlined />}
        onClick={() =>
          ciscoAccount &&
          getDevices(ciscoAccount?.username, ciscoAccount?.password)
        }
      >
        Reload
      </Button>
    </>
  );

  const ciscoForm = (
    <Form
      form={form}
      name="connect"
      onFinish={onFinish}
      autoComplete="off"
      // layout={"inline"}
    >
      <Form.Item name="ciscoUser">
        <Input placeholder="Enter your Username" />
      </Form.Item>
      <Form.Item name="ciscoPass">
        <Input.Password placeholder="Enter your Password" />
      </Form.Item>
      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
          disabled={!ciscoUserInput || !ciscoPassInput}
          loading={loading}
        >
          {loading ? "Connecting" : "Connect"}
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Flex vertical gap="middle" style={{ width: "100%" }}>
      <h1 className="colorIndigo" style={{ marginBottom: 16 }}>
        Cisco Connector
      </h1>
      {!data.length ? (
        ciscoForm
      ) : (
        <Card
          title={
            <Space>
              <>{"Cisco Vmanage"}</>
              {/* <Tag bordered={false}>{"tenantId"}</Tag> */}
            </Space>
          }
          extra={
            ciscoAccount && (
              <Flex align="flex-end" gap="middle">
                <small
                  style={{ marginLeft: 8 }}
                >{`Connected with token ***** ${ciscoAccount.password?.slice(
                  -4,
                  ciscoAccount.password.length
                )} `}</small>
                <Button size="small" type="text" onClick={() => reset()}>
                  <Text type="danger">
                    <small>{"Disconnect"}</small>
                  </Text>
                </Button>
              </Flex>
            )
          }
        >
          <Tabs
            tabBarExtraContent={operations}
            type="card"
            defaultActiveKey="1"
            items={data.map(
              ({ name, columns, data, actions = null }, index) => ({
                key: `${index + 1}`,
                label: name,
                children: (
                  <BorderedContent>
                    <TabPage
                      name={name}
                      columns={columns}
                      data={data}
                      actions={actions}
                      ciscoAccount={ciscoAccount}
                    />
                  </BorderedContent>
                ),
              })
            )}
            size="large"
          />
        </Card>
      )}
    </Flex>
  );
};

export default CiscoConnector;
