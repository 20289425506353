import React, { useCallback, useEffect, useState } from "react";
import {
  CloudDownloadOutlined,
  CopyOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  Flex,
  Input,
  message,
  Radio,
  Row,
  Space,
  Typography,
  Upload,
} from "antd";
import type { RadioChangeEvent, UploadFile, UploadProps } from "antd";
import axios from "axios";
import { callApiConnector } from "../Connectors/Common/functions/_connector_call";
import { exportAsExcel } from "../../utils/exportAsExcel";

const DataExtractorPage: React.FC = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [siteId, setSiteId] = useState<string | undefined>(undefined);
  const [uploading, setUploading] = useState(false);
  const [response, setResponse] = useState<UploadResponse | null>(null);
  const [action, setAction] = useState<String>("");
  const { Dragger } = Upload;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(undefined);
  const [error, setError] = useState<any>(null);

  const reset = () => {
    setFileList([]);
    setSiteId(undefined);
    setUploading(false);
    setResponse(null);
    setAction("");
    setLoading(false);
    setData(undefined);
    setError(null);
  };

  interface UploadResponse {
    message: string;
    siteId: string;
    file_names: string[];
  }

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const account = JSON.parse(localStorage.getItem("currentAccount") || "{}");
  const access_token = user?.access_token;

  const UploadFilesComp = () => {
    const uploadFiles = async (formData: FormData): Promise<void> => {
      try {
        const response = await axios.post<UploadResponse>(
          "/api/v1/ai_data_extract",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );

        if (response.status === 200) {
          setResponse(response.data);
        } else message.warning("Something went wrong!");
      } catch (error) {
        console.log({ error });
        message.error(`Upload failed. ${error?.response?.data?.error}`);
      } finally {
        setUploading(false);
      }
    };

    const handleUpload = () => {
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append("files", file.originFileObj as File);
      });
      formData.append("site_id", siteId as string);
      formData.append("account_name", account?.name as string);
      formData.append("account_id", account?.id as string);

      console.log({ formData });

      setUploading(true);
      uploadFiles(formData);
    };

    const directoryProps = {
      name: "directory",
      directory: true,
      onRemove: (file) => {
        const newFileList = fileList.filter((item) => item.uid !== file.uid);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        setFileList((prevList) => [...prevList, file]);
        return false; // Prevent automatic upload
      },
      onChange: (info) => {
        setFileList(info.fileList);
      },
      fileList,
    };

    const fileProps = {
      name: "file",
      multiple: true,
      onRemove: (file) => {
        const newFileList = fileList.filter((item) => item.uid !== file.uid);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        setFileList((prevList) => [...prevList, file]);
        return false; // Prevent automatic upload
      },
      onChange: (info) => {
        setFileList(info.fileList);
      },
      fileList,
    };

    const props: UploadProps = {
      name: "unstructured",
      directory: true,
      multiple: true,
      onRemove: (file) => {
        const newFileList = fileList.filter((item) => item.uid !== file.uid);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        setFileList((prevList) => [...prevList, file]);
        return false; // Prevent automatic upload
      },
      onChange: (info) => {
        setFileList(info.fileList);
      },
      fileList,
    };

    const [value, setValue] = useState("files");
    const onChange = (e: RadioChangeEvent) => {
      // console.log("radio checked", e.target.value);
      setValue(e.target.value);
    };

    return (
      <Space
        direction="vertical"
        size="middle"
        style={{ display: "flex", width: "100vh", marginTop: 16 }}
      >
        {!response && (
          <Space align="start">
            <Radio.Group
              onChange={onChange}
              value={value}
              optionType="button"
              buttonStyle="solid"
            >
              <Space direction="vertical" style={{ width: "15vh" }}>
                <Radio.Button value={"files"} style={{ width: "15vh" }}>
                  Files
                </Radio.Button>
                <Radio.Button value={"folder"} style={{ width: "15vh" }}>
                  Folder
                </Radio.Button>
              </Space>
            </Radio.Group>

            <Dragger
              disabled={response || uploading}
              {...(value === "files" ? fileProps : directoryProps)}
              style={{ width: "84vh", height: "10px" }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag {value === "files" ? "files" : "a folder"} to this
                area to upload
              </p>
              <p className="ant-upload-hint"></p>
            </Dragger>
          </Space>
        )}
        <Button
          block
          type="primary"
          onClick={handleUpload}
          disabled={fileList.length === 0 || !siteId || response}
          loading={uploading}
        >
          {uploading ? "Uploading..." : "Upload Files"}
        </Button>

        {response && (
          <Alert
            message={response.message}
            description={
              <div>
                {response.message.includes("Files uploaded successfully") && (
                  <>
                    <p>
                      <strong>
                        Gen AI Gateway is now processing your data.
                        <br /> This may take up to 5 minutes. Please check back
                        later to retrieve it.
                      </strong>
                    </p>

                    <p>
                      <strong>Site ID:</strong> {response.site_id}
                    </p>
                    <p>
                      <strong>Files:</strong>
                    </p>
                    <ul>
                      {response.file_names.map((fileName, index) => (
                        <li key={index}>{fileName}</li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            }
            type={
              response.message.includes("Files uploaded successfully")
                ? "success"
                : "info"
            }
            showIcon
          />
        )}
      </Space>
    );
  };

  const handleRetrieval = async () => {
    setData(undefined);
    setAction("retrieveData");
    setLoading(true);
    const response = await callApiConnector("gen_ai", "ai_get_data", {
      site_id: siteId, //3754172
      customer_name: account?.name, //"BRIDGESTONE EUROPE-BTBE"
    });
    setData(response.data?.gen_ai);
    setError(response.data?.error);
    setLoading(false);
  };

  const [buttonText, setButtonText] = useState("Copy");
  const DataRetrieverComp = () => {
    const onClick = useCallback((text: string) => {
      setButtonText("Copied!");
      navigator.clipboard.writeText(text);
      setTimeout(() => {
        setButtonText("Copy");
      }, 2000);
    }, []);

    const exportToExcel = async () => {
      const isCreated = await exportAsExcel({
        gen_ai: data,
      });
      isCreated
        ? console.log("File Downloaded")
        : console.log("Failed to Download");
    };

    return (
      <Space
        direction="vertical"
        size="middle"
        style={{ display: "flex", width: "100vh", marginTop: 16 }}
      >
        {data && (
          <>
            <Card
              title={`Extracted data from site ${siteId}`}
              style={{ width: "100%" }}
              extra={
                <Space>
                  <Button
                    size="small"
                    style={{ width: 100 }}
                    onClick={() => onClick(JSON.stringify(data, null, 4))}
                    disabled={!data}
                    icon={<CopyOutlined />}
                  >
                    {buttonText}
                  </Button>

                  <Button
                    disabled={data?.length === 0}
                    size="small"
                    icon={<CloudDownloadOutlined />}
                    onClick={exportToExcel}
                  >
                    Download as Excel
                  </Button>
                </Space>
              }
            >
              <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                {JSON.stringify(data, null, 4)}
              </pre>
            </Card>
          </>
        )}
        {error && (
          <Alert
            message={"Something went wrong!"}
            description={error}
            type={"error"}
            showIcon
          />
        )}
      </Space>
    );
  };

  useEffect(() => {
    if (!siteId) {
      reset();
    }
  }, [siteId]);

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{ display: "flex", width: "100vh" }}
    >
      <h1 className="colorIndigo">Gen AI Data Extractor</h1>

      <Input
        style={{ width: "100%" }}
        placeholder="Enter SITE ID"
        onChange={(e) => setSiteId(e.target.value)}
        value={siteId}
      />

      <Row gutter={16}>
        <Col span={12}>
          <Button
            disabled={
              !siteId || action == "retrieveData" || uploading || response
            }
            color="primary"
            variant="outlined"
            block
            onClick={() => setAction("sendFiles")}
          >
            Send File(s) to Gen AI
          </Button>
        </Col>
        <Col span={12}>
          <Button
            loading={loading}
            disabled={!siteId || action == "sendFiles" || data || error}
            color="primary"
            variant="outlined"
            block
            onClick={() => {
              handleRetrieval();
            }}
          >
            Retrieve Data from Gen AI
          </Button>
        </Col>
      </Row>
      {action == "sendFiles" && <UploadFilesComp />}
      {action == "retrieveData" && <DataRetrieverComp />}

      <Button
        // variant="filled"
        // color="danger"
        danger
        block
        onClick={() => reset()}
        disabled={uploading || loading || siteId === undefined}
      >
        Reset
      </Button>
    </Space>
  );
};

export default DataExtractorPage;
