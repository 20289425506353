import { Space } from "antd";
import React from "react";
import BaseTable from "../../../../components/Tables/BaseTable";
import ShowRecordDetails from "../../../../components/Tables/ShowRecordDetails";
import { RightSquareOutlined, ArrowsAltOutlined } from "@ant-design/icons";
import DynamicAction from "../../CiscoConnector/actions/DynamicAction";

interface RowAction {
  key: string;
  component: any;
  icon: React.JSX.Element;
  props?: any;
}

export default function TabPage({
  name,
  columns,
  data,
  actions,
  ciscoAccount,
}) {
  console.log({ actions });

  type ActionType = {
    label: string;
    endpoint: string;
    response_key: string;
    icon_name?: string;
    id_name: string;
  };

  const actionMenu: RowAction[] = [
    {
      key: "listDetails",
      component: ShowRecordDetails,
      icon: <ArrowsAltOutlined />,
    },
    ...actions.map(({ label, endpoint, id_name }: ActionType) => ({
      key: label,
      component: DynamicAction,
      props: {
        ciscoAccount,
        endpoint,
        id_name,
        label,
      },
      icon: <RightSquareOutlined />,
    })),
  ];

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ width: "100%", marginTop: 16 }}
    >
      {/* {contextHolder} */}
      <BaseTable
        // loading={loadingTenantData && !errorTenantData}
        tableName={name}
        dataSource={data}
        columns={columns}
        actions={actions}
        rowActions={actionMenu}
      />
    </Space>
  );
}
