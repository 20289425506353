import { downloadDocument } from "../../common/utils.js";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import { useState, useEffect, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Stack, Button, Grid, Collapse, Switch, Paper } from "@mui/material";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import Cookies from "universal-cookie";
import { handleHttpError, saveBlob, getFileName } from "../../common/utils.js";
import DataSourceReader from "./library/DataSourceReader.js";
import AdditionalDataSources from "./library/AdditionalDataSources.js";
import TemplateSelect from "./library/TemplateSelect/index.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LibraryManagerButton } from "./library/TemplateSelect/Manager/LibraryManager";
import NoAccountBanner from "../JourneyPage/NoAccountBanner";
import { LayoutPage } from "../JourneyPage/JourneyPage.js";

const cookies = new Cookies();
const access_token = cookies.get("access_token");

const currentAccount = JSON.parse(localStorage.getItem("currentAccount"));

const ngdsToken = createTheme({
  palette: {
    primary: {
      main: "#5514B4",
    },
    secondary: {
      main: "#FF80FF",
    },
    neutral: {
      main: "#1D1D1B",
    },
    light: {
      main: "#bbb",
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "80px",
    marginBottom: "80px",
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    minWidth: "100%",
  },
  grid: {
    marginTop: "6rem",
  },
  mystyle: {
    color: "rgb(85, 20, 180)",
    paddingTop: 30,
  },
  content: {
    height: 240,
    objectFit: "contain",
    textAlign: "center",
  },
  button: {
    minWidth: "225px",
    marginBottom: "1%",
    paddingTop: "3%",
    justifyContent: "center",
    textAlign: "center",
    display: "inline-block",
  },
  button_container: {
    display: "inline-block",
    marginRight: "10%",
    marginLeft: "10%",
  },
  textarea: {
    resize: "none",
    width: "600px",
    height: "120px",
    overflow: "auto",
    outline: "none",
    marginBottom: "20px",
  },
  inputfile: {
    padding: 10,
    backgroundColor: "white",
    color: "rgb(85, 20, 180)",
    width: "100%",
    borderRadius: 3,
    marginBottom: 10,
    // boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.1)",
  },
  inputTemplate: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 10,
    backgroundColor: "white",
    color: "rgb(85, 20, 180)",
    width: "100%",
    borderRadius: 3,
    marginBottom: 10,
    // boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
  },
  engine: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexLastRight: {
    marginLeft: "auto",
  },
  uploadbutton: {
    paddingTop: 15,
  },
  dcfdownload: {
    paddingTop: "3%",
  },
}));

export default function TemplatesPage() {
  const classes = useStyles();
  const cookies = new Cookies();
  const accounts = cookies.get("accounts");

  const [mainDataSource, setMainDataSource] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [additionalSources, setAdditionalSources] = useState([]);
  const [sitesSelected, setSitesSelected] = useState([]);
  const [disabledButton, setDisabledButton] = useState(true);
  const [progress, setProgress] = useState(0);
  const [sites, setSites] = useState([]);
  const [dataSourceError, setDataSourceError] = useState(false);
  const [isLoading, setIsLoading] = useState(0);
  const [openAdditonal, setOpenAdditional] = useState(false);
  const [mainSheetPrepend, setMainSheetPrepend] = useState(false);
  const [labelOptions, setLabelOptions] = useState([]);
  const [showManager, setShowManager] = useState(false);
  const [refresh, setRefresh] = useState(false);

  //Template Specifc States
  const [location, setLocation] = useState("custom");
  const [libraryId, setLibraryId] = useState(0);
  const [templateId, setTemplateId] = useState(0);
  const [labelId, setLabelId] = useState(0);
  const [templatesOptions, setTemplatesOptions] = useState([]);
  const [libraryOptions, setLibraryOptions] = useState([]);

  const toogleAdditional = () => {
    setOpenAdditional(!openAdditonal);
  };

  // Sets the Journey_ID
  const setJourneyId = () => {
    axios({
      method: "post",
      url: "/api/v1/stats/journeys",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    })
      .then((res) => {
        cookies.set("journey_id", res.data["id"], { path: "/" });
      })
      .catch((err) => {
        handleHttpError(err);
      });
  };

  const [startProgress, setStartProgress] = useState(false);
  useEffect(() => {
    let timer = null;
    if (startProgress) {
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 100;
          }
          const newProgress = oldProgress + 1;
          return newProgress > 75 ? 75 : newProgress;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [startProgress]);

  useEffect(() => {
    if (templates.length == 0) {
      setProgress(0);
    }
  }, [templates]);

  const downloadDocumentCustom = async (file, context) => {
    const account = JSON.parse(localStorage.getItem("currentAccount"));
    const account_name = account?.name;
    const account_id = account?.id;
    const journey_id = cookies.get("journey_id");
    const bodyFormData = new FormData();
    bodyFormData.append("document", file);
    bodyFormData.append("context", JSON.stringify(context));
    let url = `/api/v1/documents?journey_id=${journey_id}`; // &template_engine=${engineName}&engine_id=${mapperID}
    if (account_id !== "None") {
      url = `${url}&account_id=${account_id}`;
    }
    await axios({
      method: "post",
      url: url,
      data: bodyFormData,
      headers: {
        Authorization: "Bearer " + access_token,
        Accept: "application/octet-stream",
      },
      responseType: "blob",
    })
      .then((response) => {
        const fileName = getFileName(response.headers);
        saveBlob(response.data, fileName);
      })
      .catch((error) => {
        handleHttpError(error);
        setProgress(0);
      });
  };

  const generateDocumentCustom = async () => {
    setStartProgress(true);
    let progressInitial = 5;
    setProgress(progressInitial);
    const bodyFormData = new FormData();
    bodyFormData.append("data_source", mainDataSource);
    additionalSources.map((dataSource) =>
      bodyFormData.append("data_source", dataSource)
    );
    try {
      const res = await axios({
        method: "post",
        url: `/api/v1/contexts?site_list=${sitesSelected}&main_sheet_prepend_all=${mainSheetPrepend}`,
        data: bodyFormData,
        headers: {
          Authorization: "Bearer " + access_token,
          "content-type": "xlsx",
        },
      });
      const qtyTemplates = templates.length;
      const totalDownloads = qtyTemplates * res.data.length;
      const progressSlice = (100 - progressInitial) / totalDownloads;
      for (const template of templates) {
        for (const context of res.data) {
          if (template?.source === "remote") {
            const templateId = template.value;
            await downloadDocument(templateId, context);
          } else {
            await downloadDocumentCustom(template, context);
          }
          progressInitial = progressInitial + progressSlice;
          setProgress(progressInitial);
        }
      }
    } catch (error) {
      handleHttpError(error);
      setProgress(0);
    }
  };

  useEffect(() => {
    setDisabledButton(
      !(
        mainDataSource &&
        templates.length > 0 &&
        sitesSelected.length > 0 &&
        (progress === 100 || progress === 0)
      )
    );
  }, [mainDataSource, templates, sitesSelected, progress]);

  const handleReset = () => {
    setMainDataSource(null);
    setTemplates([]);
    setAdditionalSources([]);
    setSitesSelected([]);
    setProgress(0);
    setSites([]);
    setDataSourceError(false);
    setIsLoading(0);
    setOpenAdditional(false);
    setMainSheetPrepend(false);
    setLocation("custom");
    setLibraryId(0);
    setTemplateId(0);
    setTemplatesOptions([]);
    setLibraryOptions([]);
    setLabelOptions([]);
    setLabelId(0);
    setStartProgress(false);
    setShowManager(false);
  };

  useEffect(() => {
    handleReset();
  }, [currentAccount]);

  const [isLibraryAdmin, setIsLibraryAdmin] = useState(false);
  useEffect(() => {
    const scopes = cookies.get("scopes");
    if (scopes.includes("template_admin") || scopes.includes("admin")) {
      setIsLibraryAdmin(true);
    }
  });

  return (
    <ThemeProvider theme={ngdsToken}>
      <LayoutPage>
        <Grid item xs={12}>
          <div className="title-container">
            <h1 className="colorIndigo">Multi-Template Generator</h1>
          </div>
        </Grid>

        <Grid item xs={12}>
          {accounts?.length === 0 && <NoAccountBanner />}
        </Grid>

        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <h3 className="colorIndigo">Data Source</h3>
            <DataSourceReader
              classes={classes}
              mainDataSource={mainDataSource}
              setMainDataSource={setMainDataSource}
              setJourneyId={setJourneyId}
              sitesSelected={sitesSelected}
              setSitesSelected={setSitesSelected}
              sites={sites}
              setSites={setSites}
              dataSourceError={dataSourceError}
              setDataSourceError={setDataSourceError}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              mainSheetPrepend={mainSheetPrepend}
              setMainSheetPrepend={setMainSheetPrepend}
            />
            <Grid item xs={12} marginTop={2}>
              {sitesSelected.length > 0 && (
                <>
                  <Stack direction="row" spacing={1}>
                    <p className="colorIndigo" style={{ marginLeft: 5 }}>
                      Additional Data Source (Optional)
                    </p>
                    <Switch
                      onClick={toogleAdditional}
                      size="small"
                      color="default"
                    />
                  </Stack>

                  <Collapse in={openAdditonal} timeout="auto">
                    <AdditionalDataSources
                      additionalSources={additionalSources}
                      setAdditionalSources={setAdditionalSources}
                      classes={classes}
                    />
                    <small>
                      Make sure it has an indexing column to correlate to the
                      main data source. <br />
                      Worksheet names in the additional sources cannot be
                      duplicated to the main data source sheets.
                    </small>
                  </Collapse>
                </>
              )}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} marginTop={2}>
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Stack direction="row">
              <h3 className="colorIndigo">Templates</h3>
              <div className={classes.flexLastRight}>
                {isLibraryAdmin && libraryId > 0 && (
                  <LibraryManagerButton
                    showManager={showManager}
                    setShowManager={setShowManager}
                  />
                )}
              </div>
            </Stack>

            <TemplateSelect
              templates={templates}
              setTemplates={setTemplates}
              classes={classes}
              location={location}
              setLocation={setLocation}
              libraryId={libraryId}
              setLibraryId={setLibraryId}
              templateId={templateId}
              setTemplateId={setTemplateId}
              templatesOptions={templatesOptions}
              setTemplatesOptions={setTemplatesOptions}
              libraryOptions={libraryOptions}
              setLibraryOptions={setLibraryOptions}
              labelId={labelId}
              setLabelId={setLabelId}
              showManager={showManager}
              setShowManager={setShowManager}
              labelOptions={labelOptions}
              setLabelOptions={setLabelOptions}
              refresh={refresh}
              setRefresh={setRefresh}
              setStartProgress={setStartProgress}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          {progress > 0 && templates?.length > 0 && (
            <LinearProgress
              sx={{ marginX: 0.2 }}
              variant="determinate"
              value={progress}
            />
          )}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={disabledButton}
            onClick={generateDocumentCustom}
          >
            Generate Documents
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={handleReset}
          >
            Reset
          </Button>
        </Grid>
      </LayoutPage>
    </ThemeProvider>
  );
}
